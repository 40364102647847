<template>
  <div :class="`modal px-6 ${isActive ? 'is-active' : ''}`">
    <div :class="`modal-background ${hasClickableBackground ? 'is-clickable' : 'disabled'}`" @click="closeModal"></div>
    <div class="modal-content has-background-white has-text-centered">
      <img src="@assets/icons/close.svg" alt="close" class="icon-close is-clickable" @click="closeModal" v-if="hasCloseIcon">
      <div class="modal-header">{{title}}</div>
      <div class="modal-text">{{text}}</div>
      <slot></slot>
      <b-button type="is-blue button-ok" class="has-text-weight-bold" @click="closeModal">{{buttonText}}</b-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: 'OK'
    },
    showModal: {
      type: Boolean,
      default: false
    },
    hasCloseIcon: {
      type: Boolean,
      default: true
    },
    hasClickableBackground: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  created() {
    this.isActive = this.showModal
  },
  watch: {
    showModal(newValue) {
      this.isActive = newValue
    }
  },
  methods: {
    closeModal() {
      this.isActive = false
      this.$emit('closeModal')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.modal {
  z-index: 100;
  .modal-content {
    max-width: $mobile-wrapper-width;
    position: relative;
    border-radius: 12px;
    padding: 48px 16px 24px;
    .icon-close {
      width: 18px;
      position: absolute;
      left: 16px;
      top: 14px;
    }
    .modal-header {
      font-weight: 600;
      font-size: 20px;
      padding-bottom: 20px;
    }
    .modal-text {
      font-size: 16px;
      padding-bottom: 30px;
      line-height: 20px;
      z-index: 200;
    }
    .button-ok {
      width: 127px;
      font-size: 16px;
      height: 43px;
      border-radius: 12px;
      z-index: 300;
    }
  }
  .disabled {
    pointer-events: none;
  }
}
</style>

<template>
  <section class="search-wrap">
    <b-field class="fuzzy-search">
      <b-input
        type="text"
        icon="search"
        v-model.trim="transactionFilter"
        placeholder="Investor Last Name or Transaction ID"
        icon-right="close-circle"
        icon-right-clickable
        @icon-right-click="clearFilter('transaction')"
      ></b-input>
    </b-field>
    <multiselect
      v-model="selectedStatus"
      class="select-status ml-3"
      :options="statusOptions"
      label="label"
      track-by="key"
      :multiple="false"
      :close-on-select="true"
      placeholder="Filter by Status"
      v-if="statusOptions.length"
    ></multiselect>
    <b-field class="select-dates ml-3 pr-3">
      <b-datepicker
        range trap-focus
        :max-date="new Date()"
        position="is-bottom-left"
        v-model="selectedDates"
        placeholder="Select Dates"
        icon="calendar-week"
        icon-right-clickable
        icon-right="close-circle"
        @icon-right-click="clearFilter('dates')"
      ></b-datepicker>
    </b-field>
    <b-button
      class="is-blue has-text-weight-semibold filter-button"
      icon-left="filter"
      @click="setFilterAndFetch"
    >Filter</b-button>
  </section>
</template>
<script>
import Multiselect from 'vue-multiselect'

export default {
  data() {
    return {
      transactionFilter: null,
      selectedStatus: null,
      selectedDates: null,
    }
  },
  props: {
    statusOptions: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Multiselect,
  },
  computed: {
    datePickerRightIcon() {
      return document.querySelector('.datepicker .icon.is-right.is-clickable')
    },
    startDate() {
      if (!this.selectedDates) return null
      return this.selectedDates[0]
    },
    endDate() {
      if (!this.selectedDates) return null
      return this.selectedDates[1]
    },
  },
  mounted() {
    // add click event to close icon in datepicker because Buefy is not doing it
    this.addDatePickerRightIconClickEvent()
  },
  beforeDestroy() {
    this.removeEventListeners()
  },
  methods: {
    removeEventListeners() {
      this.datePickerRightIcon.removeEventListener('click', this.clearFilter)
    },
    addDatePickerRightIconClickEvent() {
      this.datePickerRightIcon.addEventListener('click', this.clearFilter)
    },
    clearFilter(type) {
      if (type === 'transaction') this.transactionFilter = null
      else this.selectedDates = null
    },
    setFilterAndFetch() {
      this.$emit('setFilterAndFetch', {
        transactionFilter: this.transactionFilter,
        statusFilter: this.selectedStatus ? this.selectedStatus.key : '',
        startDate: this.startDate,
        endDate: this.endDate
      })
    },
  }
}
</script>
<style lang="scss" scoped>
@import '@assets/style/addy-variables.scss';
.search-wrap {
  ::v-deep i {
    color: unset;
  }
  display: flex;
  .filter-button {
    background-color: var(--addy-blue-light-1);
    border-radius: 10px;
    padding: 0 20px;
  }
  .multiselect {
    min-width: 250px;
    max-width: 250px;
    &.multiselect--active {
      ::v-deep .multiselect__tags {
        border-color: $addyBlue;
        &:hover {
          border-color: $addyBlue;
        }
      }
    }
    ::v-deep .multiselect__tags {
      border-radius: 12px;
      border: 0.0625rem solid #d6d6d6;
      box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
      &:hover {
        border-color: #b4b4b4;
      }
      .multiselect__input,
      .multiselect__placeholder,
      .multiselect__single {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 20px;
        margin-bottom: unset;
        padding-top: unset;
      }
      .multiselect__placeholder {
        color: #d6d6d6;
      }
    }
  }
  .field {
    &.fuzzy-search {
      width: 80%;
    }
    &.select-dates {
      width: 50%;
    }
  }
  ::v-deep.control.is-blue .select::after {
    border-color: var(--addy-blue-light-1) !important;
  }
  ::v-deep div.datepicker {
    .pagination i {
      color: var(--addy-blue-light-1);
    }
    .select:not(.is-multiple):not(.is-loading)::after {
      border-color: var(--addy-blue-light-1);
    }
    .datepicker-table .datepicker-body .datepicker-cell {
      &.is-today {
        border-color: var(--addy-blue-light-1);
      }
      &.is-selected {
        background-color: var(--addy-blue-light-1);
      }
      &.is-within-selected {
        background-color: var(--addy-blue-light-3);
      }
      &.is-unselectable {
        span {
          color: #b5b5b5 !important;
        }
      }
    }
  }
}
</style>

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('addyPlusBase', ['corporationsForSideMenu']),
    corporationId() {
      return this.$route.params.corporationId
    },
    corporationName() {
      return this.corporationsForSideMenu[this.corporationId]?.name
    },
  },
}

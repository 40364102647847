// for transaction ledger, csr will always have approved status
const transactionStatusOptions = [
  // IMBY internal transaction statuses
  // { key: 'token_escrowed', label: 'Token Escrowed' },
  // { key: 'subscription_agreement_signed', label: 'Subscription Agreement Signed' },
  // { key: 'shareholder_agreement_signed', label: 'Shareholder Agreement Signed' },
  // { key: 'agreements_signed', label: 'Agreements Signed' },
  // { key: 'purchase_approved', label: 'Purchase Approved' },
  // { key: 'purchase_rejected', label: 'Purchase Rejected' },
  // { key: 'purchase_error', label: 'Purchase Error' }
  // addyPlus ledger transaction statuses
  { key: 'pending_kyc', label: 'Pending KYC' },
  { key: 'pending_suitability', label: 'Pending Suitability' },
  { key: 'pending_dealer_approval', label: 'Pending Dealer Approval' },
  { key: 'pending_fund_settlement', label: 'Pending Fund Settlement' },
  { key: 'dealer_approved', label: 'Dealer Approved' },
]

const kycStatusOptions = [
  { key: 'failed', label: 'Failed' },
  { key: 'pending', label: 'Pending' },
  { key: 'verified', label: 'Verified' }
]

const suitabilityStatusOptions = [
  { key: 'rejected', label: 'Rejected' },
  { key: 'pending', label: 'Pending' },
  { key: 'approved', label: 'Approved' },
  { key: 'follow_up', label: 'Follow up' }
]

export { transactionStatusOptions, suitabilityStatusOptions, kycStatusOptions }

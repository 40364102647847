<template>
  <div :class="`modal px-5 ${isActive ? 'is-active' : ''}`">
    <div class="modal-background"></div>
    <div class="modal-content has-background-white has-text-centered">
      <img src="@assets/icons/close.svg" alt="close" class="icon-close is-clickable" @click="closeModal">
      <div
        :class="`modal-title has-text-blue ${type === 'note' ? 'has-text-large' : 'has-text-small has-large-padding'}`"
      >{{ title }}</div>
      <div
        class="modal-subtitle has-text-left has-padding-x"
        v-for="(subTitle, key) in subTitles"
        :key="key"
        v-html="formatSubtitle(subTitle)"
      ></div>
      <div class="modal-content has-text-left">
        <div v-if="type === 'limit'">
          <b-field :label="inputHeader">
            <b-input placeholder="1000" type="number" v-model="input" min="0"></b-input>
          </b-field>
          <div>
            <span class="has-text-weight-semibold">Note:</span> Pending Investments above the maximum amount will be silently auto rejected from newest to oldest.
            Member will <span class="has-text-weight-semibold">not</span> be notified.
          </div>
        </div>
        <div v-else class="is-relative">
          <b-field :label="selectHeader">
            <b-select placeholder="Select a status" class="status-select" v-model="selected" :disabled="kycVerified">
              <option
                v-for="option in selectOptions"
                :key="option.key"
                :value="option.key"
                :disabled="type === 'kyc' && option.key !== 'verified'"
              >{{ option.label }}</option>
            </b-select>
          </b-field>
          <b-field :label="inputHeader">
            <b-input
              placeholder="Add notes"
              maxlength="200"
              type="textarea"
              v-model="input"
              :disabled="kycVerified || kycVerifiedNotSelected"
              ></b-input>
          </b-field>
          <div class="help is-danger" v-show="kycVerified">Unable to manually approve already verified investor.</div>
          <div class="help is-danger" v-show="!kycVerified && requireNote">Note is required for manually approving KYC.</div>
        </div>
        <div class="paper-trail" v-if="type === 'kyc' && paperTrails.length > 0">
          <b-field label="History">
            <b-collapse
              class="card mb-1"
              animation="slide"
              v-for="(paperTrail, index) of paperTrails"
              :key="index"
              :open="expandedHistoryIndex == index"
              @open="expandedHistoryIndex = index"
            >
              <template #trigger="props">
                <div class="card-header" role="button">
                  <p class="card-header-title">
                    {{ toReadableDate(paperTrail.when) }}
                    {{ paperTrail.who }}
                  </p>
                  <a class="card-header-icon">
                    <b-icon
                      :icon="props.open ? 'sort-up' : 'sort-down'">
                    </b-icon>
                  </a>
                </div>
              </template>
              <div class="card-content">
                <div class="content">
                  <div>{{ paperTrail.how }}</div>
                  <ul
                  v-for="(item, index) in paperTrail.what"
                  :key="index"
                  >
                    <li>{{ item }}</li></ul>
                </div>
              </div>
            </b-collapse>
          </b-field>
        </div>
      </div>
      <div class="is-flex is-justify-content-space-between buttons">
        <b-button
          type="is-blue"
          :class="`has-text-weight-bold button-continue ${isLoading ? 'is-loading' : ''}`"
          :disabled="continueDisabled"
          @click="doContinue"
        >{{ continueButtonText }}</b-button>
        <b-button
          class="has-text-weight-bold button-cancel"
          :disabled="isLoading"
          @click="closeModal"
        >{{ cancelButtonText }}</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { DateTime } from 'luxon'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitles: {
      type: Array,
      default: () => []
    },
    text: {
      type: String,
      default: ''
    },
    showModal: {
      type: Boolean,
      default: false
    },
    continueButtonText: {
      type: String,
      default: 'Submit'
    },
    cancelButtonText: {
      type: String,
      default: 'Close'
    },
    selectHeader: {
      type: String,
      default: 'Status'
    },
    selectOptions: {
      type: Array,
      default: () => []
    },
    currentSelectedOption: {
      type: String,
      default: ''
    },
    currentNote: {
      type: String,
      default: ''
    },
    currentInput: {
      type: Number,
      default: null
    },
    type: {
      type: String,
      default: 'suitability',
      validator(value) {
        return ['suitability', 'limit', 'kyc'].includes(value)
      },
    },
    paperTrails: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      isActive: false,
      isLoading: false,
      selected: '',
      input: null,
      expandedHistoryIndex: null,
    }
  },
  computed: {
    inputHeader() {
      return this.type === 'limit' ? 'Max Investment Amount' : 'Note'
    },
    verifiedSelected() {
      return this.selected === 'verified'
    },
    requireNote() {
      return this.verifiedSelected && !this.input
    },
    kycVerifiedNotSelected() {
      return this.type === 'kyc' && !this.verifiedSelected
    },
    kycVerified() {
      return this.currentSelectedOption === 'verified' && this.verifiedSelected
    },
    noChange() {
      if (this.type === 'limit') return this.input === this.currentInput
      return this.currentSelectedOption === this.selected && this.currentNote === this.input
      // return this.input === this.currentInput && (this.currentSelectedOption === this.selected && this.currentNote === this.input)
    },
    continueDisabled() {
      if (this.noChange) return true
      else if (this.type === 'limit') return this.input < 0
      else if (this.type === 'suitability') return !this.selected
      return !this.verifiedSelected || this.requireNote || this.kycVerified
    }
  },
  created() {
    this.isActive = this.showModal
  },
  watch: {
    showModal(newValue) {
      this.isActive = newValue
      this.isLoading = false
      this.selected = this.currentSelectedOption
      if (this.type === 'limit') this.input = this.currentInput
      else this.input = this.currentNote
    }
  },
  methods: {
    formatSubtitle(text) {
      const hashReg = /.+:/
      return text.replace(hashReg, '<b>$&</b>')
    },
    closeModal() {
      this.isActive = false
      this.$emit('closeModal')
    },
    doContinue() {
      this.isLoading = true
      if (this.type === 'suitability') this.$emit('setSuitabilityStatus', this.selected, this.input)
      else if (this.type === 'limit') this.$emit('setCustomMaxLimit', this.input)
      else this.$emit('setKycStatus', this.input)
    },
    toReadableDate(dateString) {
      return DateTime.fromISO(dateString).toFormat('yyyy-LL-dd HH:mm:ss')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.modal {
  z-index: 101;
  .modal-content {
    max-width: $mobile-wrapper-width;
    position: relative;
    border-radius: 12px;
    padding: 24px 40px;
    font-size: 16px;
    .icon-close {
      width: 40px;
      position: absolute;
      left: 16px;
      top: 14px;
    }
    .modal-title {
      &.has-text-small {
        font-size: 26px;
      }
      &.has-text-large {
        font-size: 36px;
      }
      &.has-large-padding {
        padding: 0 40px 20px;
      }
      font-weight: 600;
      padding-bottom: 10px;
    }
    .modal-subtitle {
      &.has-padding-x {
        padding: 0 40px;
      }
      ::v-deep b{
        font-weight: 600;
      }
      font-size: 18px;
      padding-bottom: 5px;
    }
    .help.is-danger {
      position: absolute;
      bottom: 0px;
    }
    .buttons {
      padding: 0 40px;
      .button-continue {
        width: calc(50% - 8px);
        font-size: 16px;
        height: 43px;
        border-radius: 12px;
      }
      .button-cancel {
        width: calc(50% - 8px);
        font-size: 16px;
        height: 43px;
        border-radius: 12px;
        border-color: #4A26AA;
      }
      ::v-deep .button-cancel > span {
        color: #4A26AA;
      }
    }
    .paper-trail {
      ::v-deep .field .field-body .field.has-addons {
        flex-direction: column;
        // li::marker {
        //   margin-right: 5px
        // }
      }
    }
  }
  ::v-deep .status-select > .select {
    width: 100%;
    > select {
      width: 100%;
    }
  }
}
</style>

<template>
  <div class="ledger-wrapper">
    <div class="level is-align-items-flex-start">
      <div class="level-left">
        <slot></slot>
      </div>
      <div class="level-right">
        <b-select v-if="canCreate" placeholder="Import / Export" v-model="selectedAction" @input="onSelectAction" :loading="isLoadingAction" :disabled="isLoadingAction">
          <optgroup v-for="action in actions" :label="action.label" :key="action.label">
            <option v-for="option in action.options" :value="option" :key="option">{{option}}</option>
          </optgroup>
        </b-select>
      </div>
    </div>
    <Tabs/>
    <section>
      <div class="is-clearfix">
        <button
          v-if="!isCSR"
          class="approve-button button has-background-blue is-small-height has-text-weight-semibold is-pulled-right mb-4"
          @click="approveAllSelectedTransactions"
          :disabled="!(selectedTransactions && selectedTransactions.length)">{{ actionForTransaction }} Selected Transaction(s)</button>
      </div>
      <TransactionsSearch
        :statusOptions="isCSR ? [] : transactionStatuses"
        @setFilterAndFetch="setFilterAndFetch"
      >
      </TransactionsSearch>
      <TransactionsTable
        ref="transactionsTable"
        :transactionsData="transactionsData"
        :columnHeaders="columnHeaders"
        :columnsVisible="columnsVisible"
        :filterState="filterState"
        :loadedAllTransactions="loadedAllTransactions"
        :tableIsLoading="tableIsLoading"
        @incrementPageAndFetch="incrementPageAndFetch"
        @openSuitabilityAuditModal="openSuitabilityAuditModal"
        @openSetLimitModal="openSetLimitModal"
        @openKycEditModal="openKycEditModal"
        @openFileUploadModal="openFileUploadModal"
        @onSort="onSort"
        @onRowSelected="onTransactionsTableRowSelected"
        :actionForTransaction="actionForTransaction"
      >
        <treeselect
          class="mb-3"
          :multiple="true"
          v-model="columnsVisible"
          @input="onFilterInput"
          @select="onFilterSelect"
          @deselect="onFilterDeselect"
          :options="treeSelectOptions"
          value-consists-of="LEAF_PRIORITY"
          :limit="0"
          :limitText="(count) => `Showing ${count} Column${count === 1 ? '' : 's'}`"
          :defaultExpandLevel="1"
          :backspaceRemoves="false"
          :deleteRemoves="false"
          placeholder="Show Columns"
          :beforeClearAll="manualClearAllFilters"
        />
      </TransactionsTable>
    </section>
    <ConfirmModal
      :text="confirmText"
      :showModal="showConfirmModal"
      @closeModal="showConfirmModal = false"
    >
    </ConfirmModal>
    <TransactionAprrovalModal
      class="transation-approval-modal"
      :title="transactionApprovalModalTitle"
      continueButtonText="Confirm"
      :text="approvalModal.text"
      :showModal="approvalModal.active"
      :hasCloseIcon="false"
      :hasClickableBackground="false"
      :hasCancelButtonOnLeft="true"
      :continueButtonDisabled="!approvalModal.agreeChecked"
      @cancelAction="approvalModal.active = false"
      @doContinue="onActionConfirm"
    >
      <div class="custom-slot has-text-left">
        <div v-if="isIssuer">
          <div class="mb-3">{{ transactionApprovalModalIssuerText }}</div>
          <div class="mb-3 approval-text-list-1" v-for="(list1Text, index) in transactionApprovalModalIssuerTextList1" :key="`list1-${index}`">
          {{ list1Text }}</div>
          <div class="mb-3 approval-text-list-2" v-for="(list2Text, index) in transactionApprovalModalIssuerTextList2" :key="`list2-${index}`">
          {{ list2Text }}</div>
        </div>
        <div class="mb-3" v-else>{{ transactionApprovalModalDealerText }}</div>
        <div class="mb-3">{{ transactionApprovalModalCommonText }}</div>
        <b-checkbox v-model="approvalModal.agreeChecked" type="is-blue" size="is-medium">Agree</b-checkbox>
      </div>
    </TransactionAprrovalModal>
    <StatusAndNoteModal
      :title="updateModalTitle"
      :subTitles="updateModalSubtitles"
      :showModal="showUpdateModal"
      :selectOptions="updateModalSelectOptions"
      :currentSelectedOption="currentSelectedOption"
      :currentNote="currentNote"
      :currentInput="currentInput"
      :type="updateModalType"
      :paperTrails="kycPaperTrails"
      @closeModal="closeUpdateModal"
      @setSuitabilityStatus="setSuitabilityStatus"
      @setCustomMaxLimit="setCustomMaxLimit"
      @setKycStatus="setKycStatus"
    ></StatusAndNoteModal>
    <FileUploadModal
      :transactionId="fileUploadTransactionId"
      :docType="fileUploadDocType"
      @closeModal="closeFileUploadModal"
      @onSubmit="uploadFile"/>
    <Flash title="Congrats" :content="flashContent" :show="!!flashContent" :hideFlash="hideFlash"></Flash>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { csrDownload, fetchPaginatedCSR as fetchCSR, exportSuitabilityReport } from '@api/addy-plus/corporations'
import {
  updateSuitabilityAudit, updateMaximumLimits, fetchInvestorSumInProperty,
  fetchInvestorKycPaperTrail, manualApproveKyc, uploadTransactionAllContracts
} from '@api/addy-plus/buys'
import { updateExternalInvestment } from '@api/addy-plus/external-investments'
import { bulkApproveTransactions, bulkCloseTransactions } from '@api/addy-plus/transaction_ledger'
import Tabs from '@views/addy_plus/corporations/Tabs.vue'
import TransactionsSearch from '@components/search/transactions-search.vue'
import TransactionsTable from '@components/table/transactions-table.vue'
import ConfirmModal from '@components/modal/confirm-modal.vue'
import TransactionAprrovalModal from '@components/modal/continue-or-cancel-modal.vue'
import StatusAndNoteModal from '@components/modal/select-and-text-input-modal.vue'
import FileUploadModal from '@components/modal/file-upload-modal.vue'
import Flash from '@components/modal/slide-down-flash.vue'
import Treeselect from '@riophae/vue-treeselect'
import { fixedColumn, investorColumns, transactionColumns, suitabilityColumns } from '@utils/data/addy_plus_transactions_table_column_header_map.js'
import { transactionStatusOptions, suitabilityStatusOptions, kycStatusOptions } from '@utils/data/csr_status_options.js'
import { downloadFileFromUrl } from '@utils/common-methods/common'
import { TransactionApproval } from '@utils/data/transaction_approval.ts'

export default {
  title: 'Corporation CSR',
  components: {
    Tabs,
    TransactionsSearch,
    TransactionsTable,
    ConfirmModal,
    TransactionAprrovalModal,
    Treeselect,
    StatusAndNoteModal,
    FileUploadModal,
    Flash,
  },
  data() {
    return {
      showConfirmModal: false,
      confirmText: '',
      transactionsData: [],
      columnHeaders: {},
      columnsVisible: [],
      treeSelectOptions: [],
      initialInvestorColumns: new Set(Object.keys(investorColumns)),
      initialTransactionColumns: new Set(Object.keys(transactionColumns)),
      initialSuitabilityColumns: new Set(Object.keys(suitabilityColumns)),
      filterState: {
        investorColumns: undefined,
        transactionColumns: undefined,
        suitabilityColumns: undefined,
      },
      loadedAllTransactions: false,
      tableIsLoading: false,
      pageNumber: 1,
      perPage: 13,
      transactionStatuses: Object.freeze(transactionStatusOptions),
      transactionFilter: null,
      statusFilter: null,
      startDate: null,
      endDate: null,
      suitabilityStatuses: Object.freeze(suitabilityStatusOptions),
      updateModalTitle: '',
      updateModalSubtitles: [],
      updateModalSelectOptions: [],
      showUpdateModal: false,
      transactionIdToAudit: null,
      updateModalType: undefined,
      currentNote: '',
      currentSelectedOption: '',
      kycPaperTrails: [],
      actions: Object.freeze([
        {
          label: 'Import',
          options: ['Upload Multiple Transactions', 'Upload Multiple Suitability Statuses']
        },
        {
          label: 'Export',
          options: ['Export as CSV', 'Export Suitability Report']
        },
      ]),
      selectedAction: null,
      isLoadingAction: false,
      fileUploadTransactionId: null,
      fileUploadDocType: null,
      flashContent: '',
      timer: null,
      sortField: 'dateOfInvestment',
      sortOrder: 'desc',
      selectedTransactions: null,
      approvalModal: {
        active: false,
        text: '',
        agreeChecked: false,
      },
      canAccessDealerResources: true,
      issuanceHasDealer: true,
      transactionApprovalModalTitle: TransactionApproval.modal.title,
      transactionApprovalModalIssuerText: TransactionApproval.modal.issuerText,
      transactionApprovalModalIssuerTextList1: TransactionApproval.modal.issuerTextList1,
      transactionApprovalModalIssuerTextList2: TransactionApproval.modal.issuerTextList2,
      transactionApprovalModalDealerText: TransactionApproval.modal.dealerText,
      transactionApprovalModalCommonText: TransactionApproval.modal.commonText,
    }
  },
  computed: {
    ...mapState('addyPlusBase', ['permissions']),
    accountId() {
      return this.$route.params.accountId
    },
    corporationId() {
      return this.$route.params.corporationId
    },
    transactionToAudit() {
      return this.transactionsData.find((transaction) => transaction.transactionId === this.transactionIdToAudit)
    },
    currentInput() {
      if (!this.transactionToAudit || !this.transactionToAudit.investorCustomMaxInvestmentAmount) return null
      return Number(this.transactionToAudit.investorCustomMaxInvestmentAmount)
    },
    canCreate() {
      return this.permissions.entityCsr?.includes('create')
    },
    parseSortField() {
      const map = {
        dateOfInvestment: 'agreements_signed_at',
        investorFirstName: 'first_name',
        investorLastName: 'last_name',
        transactionStatus: 'transaction_status',
      }
      return map[this.sortField]
    },
    isCSR() {
      return this.$route.name === 'addyPlusCorporationCsr'
    },
    ledgerType() {
      return this.isCSR ? 'csr' : 'transaction_ledger'
    },
    actionForTransaction() {
      return this.isIssuer ? 'Close' : 'Approve'
    },
    isIssuer() {
      // issuer can have access to dealer resources when an issuance doesn't have a dealer
      // if user has dealer access, it is either a dealer, or an issuer without dealer
      return !this.issuanceHasDealer || !this.canAccessDealerResources
    },
  },
  created() {
    this.fetchPaginatedCSR(true)
  },
  methods: {
    approveAllSelectedTransactions() {
      const number = this.selectedTransactions.length
      this.approvalModal.text = this.isIssuer ?
        `You are about to countersign and thereby accept subscription agreements creating the obligation for you to issue securities under this offering.` :
        `You are about to approve ${number} subscription${number > 1 ? 's' : ''} to the Issuer’s offering.`
      this.approvalModal.agreeChecked = false
      this.approvalModal.active = true
    },
    async fetchPaginatedCSR(init = false, scrollToElementId = null) {
      this.tableIsLoading = true
      const payload = { page_number: this.pageNumber, page_size: this.perPage, sort_order: this.sortOrder, sort_field: this.parseSortField }
      if (this.transactionFilter) payload.transaction_filter = this.transactionFilter
      if (this.statusFilter) payload.status_filter = this.statusFilter
      if (this.startDate) payload.start_date = this.startDate
      if (this.endDate) payload.end_date = this.endDate
      payload.ledger_type = this.ledgerType

      await fetchCSR(this.corporationId, payload).then((result) => {
        if (!result.success) return
        if (!result.data.transactions.length || result.data.transactions.length < this.perPage) this.loadedAllTransactions = true
        this.transactionsData = this.pageNumber === 1 ? result.data.transactions : [...this.transactionsData, ...result.data.transactions]
        if (init) {
          this.initializeOnFirstLoad(result.data)
        }
        this.tableIsLoading = false
      }).finally(() => {
        if (init && this.transactionsData.length > 0) this.$refs.transactionsTable.initTableEvents()
        if (scrollToElementId && document.getElementById(scrollToElementId)) this.scrollToElement(scrollToElementId)
      })
    },
    initializeOnFirstLoad(data) {
      this.canAccessDealerResources = data.canAccessDealerResources
      this.issuanceHasDealer = data.issuanceHasDealer
      if (this.isIssuer) this.transactionStatusOptions = transactionStatusOptions.filter((option) => option.key !== 'pending_dealer_approval')
      this.setupTableHeaders(data)
      this.setupToggleDropDown(data.canAccessDealerResources)
    },
    setupTableHeaders(responseData) {
      if (!responseData.transactions.length) return

      const hasExternalInvestment = !!responseData.transactions.find((data) => !!data.externalInvestmentId)

      // fixed column
      const fixedColumnData = this.iterateAllColumns(fixedColumn, hasExternalInvestment)
      let columnHeaders = fixedColumnData.columnHeaders
      let columnsVisible = fixedColumnData.columnsVisible
      // investor columns
      const investorColumnsData = this.iterateAllColumns(investorColumns, hasExternalInvestment)
      columnHeaders = { ...columnHeaders, ...investorColumnsData.columnHeaders }
      columnsVisible = [...columnsVisible, ...investorColumnsData.columnsVisible]
      // transaction columns
      const transactionColumnsData = this.iterateAllColumns(transactionColumns, hasExternalInvestment)
      columnHeaders = { ...columnHeaders, ...transactionColumnsData.columnHeaders }
      columnsVisible = [...columnsVisible, ...transactionColumnsData.columnsVisible]

      if (responseData.canAccessDealerResources) {
        // suitability columns
        const suitabilityColumnsData = this.iterateAllColumns(suitabilityColumns, hasExternalInvestment)
        columnHeaders = { ...columnHeaders, ...suitabilityColumnsData.columnHeaders }
        columnsVisible = [...columnsVisible, ...suitabilityColumnsData.columnsVisible]
      }

      this.columnHeaders = columnHeaders
      this.columnsVisible = columnsVisible
    },
    iterateAllColumns(columns, hasExternalInvestment) {
      const sortableColumns = ['transactionStatus', 'investorFirstName', 'investorLastName', 'dateOfInvestment']
      const columnsToSkip = ['investorCustomMaxInvestmentAmount', 'externalInvestmentId']
      const toggledColumns = ['proofOfPayment', 'votingTrustAgreement', 'shareholderAgreement']
      const columnHeaders = {}
      const columnsVisible = []
      for (const key in columns) {
        if (Object.hasOwnProperty.call(columns, key) && !columnsToSkip.includes(key)) {
          const isToggledColumn = toggledColumns.includes(key)
          if (isToggledColumn) {
            columnHeaders[key] = { sortable: false, label: this.columnKeyToLabel(key) }
          } else {
            columnHeaders[key] = { sortable: sortableColumns.includes(key), label: this.columnKeyToLabel(key) }
          }
          (!isToggledColumn || hasExternalInvestment) && columnsVisible.push(key)

          if (key !== 'numberOfSecurities') continue
          // create a column named totalCost using the data for column numberOfSecurities
          columnHeaders.totalCost = { sortable: false, label: this.columnKeyToLabel('totalCost') }
        }
      }
      return { columnHeaders, columnsVisible }
    },
    columnKeyToLabel(key) {
      return fixedColumn[key] || investorColumns[key] || transactionColumns[key] || suitabilityColumns[key]
    },
    setupToggleDropDown(canAccessDealerResources) {
      this.treeSelectOptions = [{
        id: 'investorColumns',
        label: 'Investor Information',
        children: this.setupToggleStructure(investorColumns)
      }, {
        id: 'transactionColumns',
        label: 'Transaction Information',
        children: this.setupToggleStructure(transactionColumns)
      }]
      if (canAccessDealerResources) {
        this.treeSelectOptions.push({
          id: 'suitabilityColumns',
          label: 'Suitability Information',
          children: this.setupToggleStructure(suitabilityColumns)
        })
      }
      this.setInitialFilterState(canAccessDealerResources)
    },
    setInitialFilterState(canAccessDealerResources) {
      const initialState = {
        investorColumns: new Set(Object.keys(investorColumns)),
        transactionColumns: new Set(Object.keys(transactionColumns)),
        suitabilityColumns: canAccessDealerResources ? new Set(Object.keys(suitabilityColumns)) : new Set(),
      }
      this.filterState = initialState
    },
    setupToggleStructure(obj) {
      return Object.keys(obj).map((key) => ({ id: key, label: obj[key] }))
    },
    incrementPageAndFetch() {
      this.pageNumber++
      this.fetchPaginatedCSR()
    },
    setFilterAndFetch({ transactionFilter, statusFilter, startDate, endDate }) {
      this.transactionFilter = transactionFilter
      this.statusFilter = statusFilter
      this.startDate = startDate
      this.endDate = endDate
      this.resetTablePageNumber()
      this.fetchPaginatedCSR()
    },
    resetTablePageNumber() {
      this.pageNumber = 1
      this.loadedAllTransactions = false
    },
    openSuitabilityAuditModal(transactionId) {
      this.transactionIdToAudit = transactionId
      const { suitabilityStatus, suitabilityNote, investorFirstName, investorLastName, investorEmail } = this.transactionToAudit
      this.updateModalTitle = 'Suitability Audit'
      this.updateModalSelectOptions = this.suitabilityStatuses
      this.currentSelectedOption = suitabilityStatus
      this.currentNote = suitabilityNote || ''
      this.updateModalSubtitles = [`Name: ${investorFirstName}  ${investorLastName}`, `Email: ${investorEmail}`, `Transaction ID: ${transactionId}`]
      this.updateModalType = 'suitability'
      this.showUpdateModal = true
    },
    closeUpdateModal() {
      this.showUpdateModal = false
      // reset all modal fields
      this.updateModalType = undefined
      this.updateModalSubtitles = []
      this.currentNote = ''
      this.currentSelectedOption = ''
      this.updateModalSelectOptions = []
      this.transactionIdToAudit = null
      this.updateModalTitle = ''
    },
    setSuitabilityStatus(status, note) {
      const payload = {
        suitability_status: status,
        suitability_note: note
      }
      updateSuitabilityAudit(this.transactionIdToAudit, payload).then((res) => {
        if (res.success) {
          // change frontend data to prevent endpoint calls
          const transaction = this.transactionsData.find((transaction) => transaction.transactionId === this.transactionIdToAudit)
          transaction.suitabilityStatus = payload.suitability_status
          transaction.suitabilityNote = payload.suitability_note
          this.closeUpdateModal()
        }
      })
    },
    openSetLimitModal(transactionId) {
      this.transactionIdToAudit = transactionId
      const { investorHashid, investorFirstName, investorLastName, investorEmail } = this.transactionToAudit
      fetchInvestorSumInProperty(investorHashid, transactionId).then((result) => {
        let propertyName = null
        let propertyInvestmentSum = null
        if (result.success) {
          propertyName = result.data.propertyName
          propertyInvestmentSum = result.data.propertyInvestmentSum
        }
        this.updateModalTitle = 'Investor Max' + (propertyName ? ` for ${propertyName}` : '')
        this.updateModalSelectOptions = this.suitabilityStatuses
        this.updateModalSubtitles = [
          `First Name: ${investorFirstName}`,
          `Last Name: ${investorLastName}`,
          `Email: ${investorEmail}`
        ]
        if (propertyInvestmentSum) this.updateModalSubtitles.push(`Investor Total in Property: $${propertyInvestmentSum}`)
        this.updateModalType = 'limit'
        this.showUpdateModal = true
      })
    },
    setCustomMaxLimit(amount) {
      const payload = {
        transaction_id: this.transactionIdToAudit,
        property_maximum: amount
      }
      updateMaximumLimits(this.transactionToAudit.investorHashid, payload).then(async (res) => {
        if (res.success) {
          this.refreshTable()
        }
      })
    },
    async refreshTable() {
      const originalPageSize = this.perPage
      const originalPageNumber = this.pageNumber
      this.resetTablePageNumber()
      this.perPage = originalPageNumber * originalPageSize
      await this.fetchPaginatedCSR(false, this.transactionIdToAudit)
      this.closeUpdateModal()
      this.perPage = originalPageSize
      this.pageNumber = originalPageNumber
    },
    scrollToElement(id) {
      document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
    },
    openKycEditModal(transactionId) {
      this.transactionIdToAudit = transactionId
      const { investorHashid, kycStatus, kycNote, investorFirstName, investorLastName, investorEmail } = this.transactionToAudit
      fetchInvestorKycPaperTrail(investorHashid).then((result) => {
        if (result.success) this.kycPaperTrails = result.data
        this.updateModalTitle = 'KYC Note'
        this.updateModalSelectOptions = kycStatusOptions
        this.currentSelectedOption = kycStatus
        this.currentNote = kycNote || ''
        this.updateModalSubtitles = [`Name: ${investorFirstName}  ${investorLastName}`, `Email: ${investorEmail}`]
        this.updateModalType = 'kyc'
        this.showUpdateModal = true
      })
    },
    openFileUploadModal(txnId, docType) {
      this.fileUploadTransactionId = txnId
      this.fileUploadDocType = docType
    },
    closeFileUploadModal() {
      this.fileUploadTransactionId = null
      this.fileUploadDocType = null
    },
    uploadFile(formData) {
      if (this.fileUploadDocType === 'all_contracts') {
        uploadTransactionAllContracts(this.fileUploadTransactionId, formData).then(this.handleFileUploadResult)
      } else {
        updateExternalInvestment(this.corporationId, this.fileUploadTransactionId, formData).then(this.handleFileUploadResult)
      }
    },
    handleFileUploadResult(res) {
      this.closeFileUploadModal()

      if (!res.success) return

      this.fetchPaginatedCSR()
      this.flashContent = res.data.message
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.hideFlash()
      }, 4000)
    },
    hideFlash() {
      this.flashContent = ''
    },
    setKycStatus(note) {
      const payload = { note }
      manualApproveKyc(this.transactionToAudit.investorHashid, payload).then((res) => {
        if (res.success) {
          this.refreshTable()
        }
      })
    },
    onSelectAction(action) {
      if (!action) return
      switch (action) {
      case 'Upload Multiple Transactions':
        this.$router.push(`/addyPlus/${this.accountId}/corporations/${this.corporationId}/csr/externalInvestments/upload`)
        break
      case 'Upload Multiple Suitability Statuses':
        this.$router.push(`/addyPlus/${this.accountId}/corporations/${this.corporationId}/csr/uploadMultipleSuitabilityStatuses`)
        break
      case 'Export as CSV':
        this.onExport()
        break
      case 'Export Suitability Report':
        this.onExportSuitabilityReport()
        break
      }
    },
    onExport() {
      this.isLoadingAction = true
      const payload = { ledger_type: this.ledgerType }
      csrDownload(this.corporationId, payload).then((result) => {
        this.isLoadingAction = false
        this.selectedAction = null
        this.confirmText = !result.success ? result.error : `The CSV will be sent to your email: ${result.data.requesterEmail}. This may take a few moment!`
        this.showConfirmModal = true
      })
    },
    onExportSuitabilityReport() {
      this.isLoadingAction = true
      exportSuitabilityReport(this.corporationId).then((result) => {
        const urlCreator = window.URL || window.webkitURL
        const url = urlCreator.createObjectURL(result)
        downloadFileFromUrl(url, 'suitability-report.csv')
        this.selectedAction = null
        this.isLoadingAction = false
      })
    },
    onFilterInput(node) {
      if (!node.length) {
        for (const key in this.filterState) {
          if (Object.hasOwnProperty.call(this.filterState, key)) this.filterState[key].clear()
        }
      }
    },
    onFilterSelect(node) {
      if (node.children) { // select group
        this.filterState[node.id] = new Set(this[`initial${node.id.replace(/^\w/, node.id.charAt(0).toLocaleUpperCase())}`])
      } else { // select option
        this.initialInvestorColumns.has(node.id) && this.filterState.investorColumns.add(node.id)
        this.initialTransactionColumns.has(node.id) && this.filterState.transactionColumns.add(node.id)
        this.initialSuitabilityColumns.has(node.id) && this.filterState.suitabilityColumns.add(node.id)
      }
    },
    onFilterDeselect(node) {
      if (node.children) { // deselect group
        this.filterState[node.id].clear()
      } else { // deselect option
        for (const key in this.filterState) {
          if (Object.hasOwnProperty.call(this.filterState, key) && this.filterState[key].has(node.id)) return this.filterState[key].delete(node.id)
        }
      }
    },
    onSort(field, order) {
      this.transactionsData = []
      this.sortField = field
      this.sortOrder = order
      this.pageNumber = 1
      this.fetchPaginatedCSR()
    },
    onTransactionsTableRowSelected(transactionIds) {
      this.selectedTransactions = transactionIds
    },
    onActionConfirm() {
      if (this.isIssuer) {
        this.bulkClose()
      } else {
        this.bulkApprove()
      }
    },
    bulkApprove() {
      bulkApproveTransactions(this.corporationId, this.selectedTransactions).then((res) => {
        if (!res.success) return
        this.closeApprovalModal()
      })
    },
    bulkClose() {
      bulkCloseTransactions(this.corporationId, this.selectedTransactions).then((res) => {
        if (!res.success) return
        this.closeApprovalModal()
      })
    },
    closeApprovalModal() {
      this.fetchPaginatedCSR()
      this.approvalModal.active = false
      this.unselectAllTransactions()
    },
    unselectAllTransactions() {
      this.selectedTransactions = null
      this.$refs.transactionsTable.selectedRows.clear()
      this.$refs.transactionsTable.checkboxValues = {}
    },
    manualClearAllFilters() {
      // clear group headings
      this.filterState.investorColumns.clear()
      this.filterState.transactionColumns.clear()
      this.filterState.suitabilityColumns.clear()
      // hide all columns except sticky column
      this.columnsVisible = ['transactionStatus']
      return false // prevent default treeselect behaviour
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@assets/style/addy-variables.scss';
.ledger-wrapper {
  .b-tabs:not(:last-child) {
    margin-bottom: unset;
  }
  .buttons {
    margin-bottom: 12px;
    .button {
      background-color: var(--addy-blue-light-1) !important;
      margin-bottom: unset;
    }
    .csv-button,
    .approve-button {
      border-radius: 10px;
      padding: 0 20px;
    }
  }
  ::v-deep .vue-treeselect {
      width: 250px;
      > div.vue-treeselect__control {
        .vue-treeselect__value-container,
        .vue-treeselect__limit-tip-text {
          cursor: pointer;
        }
        .vue-treeselect__input-container {
          display: none;
        }
      }
      span.vue-treeselect__limit-tip-text {
        font-size: inherit;
        font-weight: inherit;
      }
    }
  .transation-approval-modal {
    ::v-deep .modal-text {
      text-align: left !important;
      padding-bottom: 0;
    }
    .custom-slot {
      padding: 0 20px;
      font-size: 16px;
      line-height: 20px;
      .approval-text-list-1 {
        margin-left: 16px;
        display: list-item;
        list-style-type: disc;
        list-style-position: inside;
      }
      .approval-text-list-2 {
        margin-left: 32px;
        display: list-item;
        list-style-type: circle;
        list-style-position: inside;
      }
    }
  }
}
</style>

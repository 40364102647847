const fixedColumn = {
  transactionStatus: 'Transaction Status',
}

const investorColumns = {
  investorEmail: 'Email',
  investorFirstName: 'Investor First Name',
  investorLastName: 'Investor Last Name',
  investorProvince: 'Province/State',
  kycStatus: 'KYC Status',
  kycNote: 'KYC Note',
  proofOfPayment: 'Proof of Payment',
  votingTrustAgreement: 'Voting Trust Agreement',
  shareholderAgreement: 'Shareholder Agreement',
  subscriptionAgreement: 'Subscription Agreement',
}

const transactionColumns = {
  transactionId: 'addy Transaction ID',
  numberOfSecurities: '# of Securities',
  totalCost: 'Total Cost',
  currency: 'Currency',
  dateOfInvestment: 'Date of Investment',
  dateOfCompletion: 'Date of Completion',
  paymentStatus: 'Payment Status',
  suitabilityStatus: 'Suitability Status',
  suitabilityNote: 'Suitability Note',
}

const suitabilityColumns = {
  netFinancialAssets: 'Net Financial Assets',
  preTaxAnnualIncome: 'Pre Tax Annual Income',
  shortTermCashObligations: 'Short Term Cash Obligations',
  monthlyExpenses: 'Monthly Expenses',
  investmentExperience: 'Investment Experience',
  investmentKnowledge: 'investment_knowledge',
  riskTolerance: 'Risk Tolerance',
  riskCapacity: 'Risk Capacity',
  investmentHorizon: 'Investment Horizon',
  investmentObjective: 'Investment Objective',
  previousInvestmentTypes: 'Previous Investment Types',
  employmentStatus: 'Employment Status',
  employmentOccupation: 'Employment Occupation',
  relationshipStatus: 'Relationship Status',
  citizenship: 'Citizenship',
  insiderReportingIssuer: 'Insider Reporting Issuer',
  insiderReportingIssuerRelationship: 'Insider Reporting Issuer Relationship',
  borrowedMoney: 'Borrowed Money',
  politicallyExposed: 'Politically Exposed',
  thirdPartyAccountCreation: 'Third Party Account Creation',
  thirdPartyAccountActivity: 'Third Party Account Activity',
  lessThanTenPercentAssets: 'Less Than Ten Percent Assets',
  provideTrustedContactPerson: 'Provide Trusted Contact Person',
  trustedContactPersonName: 'Trusted Contact Person Name',
  trustedContactPersonNumber: 'Trusted Contact Person Number',
}

export { fixedColumn, investorColumns, transactionColumns, suitabilityColumns }

interface TransactionApproval {
  modal: { title: string, issuerText: string, issuerTextList1: Array<String>, issuerTextList2: Array<String>, dealerText: string, commonText: string }
}

export const TransactionApproval: TransactionApproval = {
  modal: {
    title: 'Warning',
    issuerText: 'Clicking the “Confirm” button below will trigger the following process:',
    issuerTextList1: [
      'You will be provided with software generated directors consent resolutions, which, among other things, once signed, issue the securities for which subscriptions were accepted and authorize customary related processes and actions;',
      'Once the securities for which subscriptions were accepted have been issued by means of the resolutions mentioned in the preceding point or other valid authorization;',
    ],
    issuerTextList2: [
      'the platform will generate electronic security certificates to each applicable subscriber; and',
      'the securities ledger will be updated to reflect the issuance.',
      'your signature as an authorized signatory will be electronically placed on each selected subscription agreement, thereby accepting the subscription to the securities subscribed to therein;',
      'your subscriber will be notified of the acceptance of the subscription;',
    ],
    dealerText: 'Clicking the “Confirm” button below will enable the Issuer to accept the subscriptions approved by you and to issue the subscribed securities.',
    commonText: 'Please consider the impact of your decision carefully before proceeding as all or part of this process might be irreversible.'
  },
}

<template>
  <div class="transactions-table" id="transactions-table">
    <section class="table-wrap">
      <b-table
        ref="table"
        :data="transactionsData"
        :sticky-header="true"
        :striped="true"
        :bordered="true"
        :scrollable="true"
        backend-sorting
        :default-sort="[currentSortColumn, currentSortOrder]"
        :row-class="(row, index) => ({'row-selected': selectedRows.has(row.transactionId)})"
      >
        <slot></slot>
        <b-table-column
          centered
          v-for="(column, columnKey) in columnHeaders"
          :key="columnKey"
          :label="column.label"
          :field="columnKey"
          :visible="columnsVisible.includes(columnKey)"
          :sticky="columnKey === 'transactionStatus'"
        >
          <template v-slot:header>
            <div
              class="table-header is-relative is-flex is-align-items-center"
              :class="`${column.sortable ? 'is-sortable is-clickable' : ''} ${currentSortColumn === columnKey ? 'is-current-sort' : ''}`"
              @click="onSort(columnKey, column.sortable)"
            >
              {{ column.label }}
              <b-icon
                :icon="`${currentSortOrder === 'asc' ? 'chevron-up' : 'chevron-down'}`"
                size="is-small"
                class="sort-icon"
                v-if="column.sortable && currentSortColumn === columnKey"
              ></b-icon>
            </div>
          </template>
          <template v-slot="props">
            <div
              :class="`sub-agreement is-relative ${fileActionNotApplicable(props.row, columnKey) ? 'has-text-danger' : 'has-text-blue is-clickable'}`"
              @click="fileAction(props.row, columnKey)"
              v-if="['subscriptionAgreement', 'shareholderAgreement', 'votingTrustAgreement', 'proofOfPayment'].includes(columnKey)"
            >{{ fileActionText(props.row, columnKey) }}</div>
            <div class="is-flex is-align-items-center" v-else-if="columnKey === 'transactionStatus'">
              <b-checkbox
                type="is-blue"
                @input="onRowSelected(props.row.transactionId)"
                v-model="checkboxValues[props.row.transactionId]"
                :disabled="rowSelectDisabled(props.row.transactionStatus)"></b-checkbox>
              <span :class="`tag ${statusType(props.row[columnKey])}`">
                {{ props.row[columnKey] }}
              </span>
            </div>
            <div
              :class="`tag ${statusType(props.row[columnKey])}`"
              v-else-if="['transactionStatus', 'paymentStatus'].includes(columnKey) || (isCSR && ['kycStatus', 'suitabilityStatus'].includes(columnKey))"
            >{{ props.row[columnKey] }}</div>
            <template v-else-if="!isCSR && (columnKey === 'kycStatus' || columnKey === 'suitabilityStatus' || columnKey === 'investorLastName')">
              <div v-if="!props.row.investorHashid && columnKey === 'investorLastName'">{{ props.row[columnKey] }}</div>
              <v-popover
                placement="top"
                trigger="hover"
                v-else
              >
                <div
                  class="is-clickable tooltip-target"
                  :class="`${columnKey === 'investorLastName' ? 'has-text-blue' : 'tag ' + statusType(props.row[columnKey])}`"
                  @click="toolTipClickedAction(columnKey, props.row.transactionId)"
                >{{ props.row[columnKey] }}</div>
                <template v-slot:popover>
                  <div class="pr-2"><b-icon icon="pen"/>{{ columnKey === 'investorLastName' ? 'Set Max Investment Amount' : 'Click to Update' }}</div>
                </template>
              </v-popover>
            </template>
            <div v-else :id="columnKey === 'investorHashid' ? `${props.row.transactionId}` : ''">
              {{ columnKey === 'totalCost' ? `$${props.row.numberOfSecurities}` : toString(props.row[columnKey]) }}
            </div>
            <span v-if="columnKey === 'investorLastName' && props.row.investorCustomMaxInvestmentAmount">
              (Max: {{ props.row.investorCustomMaxInvestmentAmount }})
            </span>
          </template>
        </b-table-column>
        <template v-slot:footer>
          <div id="table-bottom-flag"></div>
          <div class="loading has-text-weight-semibold" v-show="!loadedAllTransactions && !allColumnsToggledOff">
            <div>retrieving data...</div>
            <b-button loading></b-button>
          </div>
          <div class="loading has-text-weight-semibold" v-show="!loadedAllTransactions && allColumnsToggledOff">
            no columns selected
          </div>
          <div class="loading has-text-weight-semibold" v-show="loadedAllTransactions">
            - end of all records -
          </div>
        </template>
      </b-table>
    </section>
  </div>
</template>

<script>
import { fetchDocUrl } from '@api/common'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { VPopover } from 'v-tooltip'
import { camelToSnakeCase } from '@utils/common-methods/common'

export default {
  data() {
    return {
      prevLeft: 0,
      currentSortColumn: 'dateOfInvestment',
      currentSortOrder: 'desc',
      selectedRows: new Set(),
      checkboxValues: {},
    }
  },
  props: {
    transactionsData: {
      type: Array,
      required: true,
    },
    columnHeaders: {
      type: Object,
      required: true,
    },
    columnsVisible: {
      type: Array,
      required: true,
    },
    filterState: {
      type: Object,
      required: true,
      validator(value) {
        const keys = Object.keys(value)
        return keys.includes('investorColumns') && keys.includes('transactionColumns') && keys.includes('suitabilityColumns')
      },
    },
    loadedAllTransactions: {
      type: Boolean,
      default: false,
    },
    tableIsLoading: {
      type: Boolean,
      default: false,
    },
    actionForTransaction: {
      type: String,
      required: true,
      validator: (value) => {
        return ['Approve', 'Close'].includes(value)
      },
    },
  },
  components: {
    VPopover,
  },
  computed: {
    tableScrollContainer() {
      return document.querySelector('#transactions-table .table-wrap .b-table .table-wrapper')
    },
    allColumnsToggledOff() {
      return this.columnsVisible.length === 0
    },
    isCSR() {
      return this.$route.name === 'addyPlusCorporationCsr'
    },
  },
  watch: {
    columnsVisible() {
      this.$nextTick(() => {
        this.addGroupHeadings()
      })
    },
  },
  beforeDestroy() {
    this.removeScrollingEvent()
  },
  methods: {
    addGroupHeadings() {
      const parentNode = document.querySelector('.table-wrapper .table thead')
      const injected = document.getElementById('injected-header')
      injected && parentNode.removeChild(injected)

      const newHeader = document.createElement('tr')
      newHeader.id = 'injected-header'

      const investorColumnsSize = this.filterState.investorColumns.size
      const transactionColumnsSize = this.filterState.transactionColumns.size
      const suitabilityColumnsSize = this.filterState.suitabilityColumns.size

      const stickyColumn = document.createElement('th')
      stickyColumn.className = 'is-sticky'
      newHeader.appendChild(stickyColumn)

      investorColumnsSize && this.createTh(investorColumnsSize, 'Investor Information', newHeader)
      transactionColumnsSize && this.createTh(transactionColumnsSize, 'Transaction Information', newHeader)
      suitabilityColumnsSize && this.createTh(suitabilityColumnsSize, 'Suitability Information', newHeader)

      parentNode.insertBefore(newHeader, parentNode.firstElementChild)
    },
    createTh(colSpan, textContent, parentNode) {
      const node = document.createElement('th')
      node.colSpan = colSpan
      node.textContent = textContent
      parentNode.appendChild(node)
    },
    fileAction(row, key) {
      if (this.fileActionNotApplicable(row, key)) return

      const txnId = row.externalInvestmentId || row.transactionId
      const docType = row.externalInvestmentId ? camelToSnakeCase(key) : 'all_contracts'

      if (this.isCSR || row[key]) {
        this.getFileUrl(row.externalInvestmentId ? 'ExternalInvestment' : 'Buy', txnId, docType)
      } else {
        this.$emit('openFileUploadModal', txnId, docType)
      }
    },
    fileActionNotApplicable(row, key) {
      return ['proofOfPayment', 'votingTrustAgreement', 'shareholderAgreement'].includes(key) && !row.externalInvestmentId
    },
    fileActionText(row, key) {
      if (this.fileActionNotApplicable(row, key)) return 'Not Applicable'

      return this.isCSR || row[key] ? 'View' : 'Upload'
    },
    async getFileUrl(modelName, id, documentType) {
      const w = window.open('')
      const response = await fetchDocUrl({
        model_name: modelName,
        hashid: id,
        document_type: documentType,
      })
      if (!response.success || response.data.url === null) {
        return w.close()
      }
      w.location = response.data.url
    },
    pullToAddData() {
      const element = document.querySelector('#table-bottom-flag')

      const currentLeft = this.tableScrollContainer.scrollLeft
      if (this.prevLeft !== currentLeft) {
        this.prevLeft = currentLeft
        return
      }

      const containerTop = this.tableScrollContainer.scrollTop
      const containerBottom = containerTop + this.tableScrollContainer.clientHeight
      const elementTop = element.offsetTop
      const elementBottom = elementTop + element.clientHeight

      // Check if in view
      const inView = (elementTop >= containerTop && elementBottom <= containerBottom)

      // fetch if flag in view, and prevent multiple fetches
      if (inView && !this.tableIsLoading && !this.loadedAllTransactions) {
        this.$emit('incrementPageAndFetch')
      }
    },
    initTableEvents() {
      // force default sort on table because Buefy is not doing it
      this.addScrollingEvent()
    },
    addScrollingEvent() {
      if (!this.transactionsData.length) return
      this.tableScrollContainer.addEventListener('scroll', this.pullToAddData)
    },
    removeScrollingEvent() {
      if (this.tableScrollContainer) this.tableScrollContainer.removeEventListener('scroll', this.pullToAddData)
    },
    statusType(status) {
      switch (status?.toLocaleLowerCase()) {
      case 'purchase_error':
      case 'purchase_rejected':
      case 'rejected':
      case 'failed':
      case 'not available':
        return 'is-danger'
      case 'purchase_approved':
      case 'approved':
      case 'verified':
      case 'manual_approved':
      case 'settled':
      case 'completed':
      case 'dealer_approved':
        return 'is-success'
      default:
        return 'is-warning'
      }
    },
    toString(data) {
      if (!Array.isArray(data)) return data
      return data.join(', ')
    },
    toolTipClickedAction(columnKey, transactionId) {
      if (columnKey === 'suitabilityStatus') this.$emit('openSuitabilityAuditModal', transactionId)
      else if (columnKey === 'investorLastName') this.$emit('openSetLimitModal', transactionId)
      else this.$emit('openKycEditModal', transactionId)
    },
    onSort(field, sortable) {
      if (!sortable) return

      this.currentSortOrder = ((this.currentSortColumn === field) && (this.currentSortOrder === 'desc')) ? 'asc' : 'desc'
      this.currentSortColumn = field
      this.$emit('onSort', field, this.currentSortOrder)
    },
    onRowSelected(transactionId) {
      if (this.selectedRows.has(transactionId)) this.selectedRows.delete(transactionId)
      else this.selectedRows.add(transactionId)
      this.$emit('onRowSelected', [...this.selectedRows])
    },
    rowSelectDisabled(transactionStatus) {
      return this.actionForTransaction === 'Approve' ? transactionStatus !== 'pending_dealer_approval' : transactionStatus !== 'dealer_approved'
    },
  }
}
</script>
<style lang="scss" scoped>
@import '@assets/style/addy-variables.scss';
.transactions-table {
  .table-wrap {
    font-size: 16px;
    ::v-deep .is-sticky {
      z-index: 5;
      background: var(--addy-blue-light-3) !important;
    }
    ::v-deep thead th {
      text-align: center;
    }
    .table-header {
      min-height: 50px;
    }
    ::v-deep tr.row-selected {
      background: var(--addy-blue-light-3) !important;
    }
    // force column width
    ::v-deep th {
      min-width: 180px;
      z-index: 5;
      vertical-align: middle;
      &.is-sticky {
        z-index: 10;
      }
    }
    ::v-deep td {
      vertical-align: middle;
    }
    // overwrite buefy built-in table settings
    ::v-deep.b-table .table-wrapper {
      height: calc(100vh - 425px) !important;
      margin-bottom: unset;
      overflow: scroll !important;
      z-index: 1;
    }
    .table-footer .loading {
      position: sticky;
      left: calc(50% - 72px);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 24px;
      margin: 8px 12px;
      .button.is-loading {
        z-index: 100;
        background: transparent !important;
        border: none;
      }
    }
    .tag {
      font-size: 14px;
      &.is-success {
        background-color: #64cd6d;
      }
      &.is-warning {
        background-color: #ffe08a;
      }
    }
    ::v-deep .table-wrapper.has-sticky-header {
      thead {
        th:not(.is-sticky) {
          background-color: #fff;
        }
        th.is-sticky {
          z-index: 99 !important;
        }
        tr:first-child th {
          height: 50px;
        }
        tr:last-child th {
          position: sticky;
          top: 50px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.tooltip {
  &.popover {
    .tooltip-inner {
      background: rgba(97,97,97,.9);
      color: #fff;
      width: auto;
      padding: 2px 10px;
      line-height: 22px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, .1);
      font-size: 12px;
      display: inline-block;
      transform: translateY(4px);
      div, .icon >i {
        color: white;
      }
    }
    .tooltip-arrow {
      display: none;
    }
  }
}
</style>

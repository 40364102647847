// @ts-ignore
import axios from '@lib/axios/middleware'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'
import { SuccessResponsePayload } from '../api'

const getImbyRoute = (url: string): string => {
  if (window.location.hostname === 'localhost') return url
  return getImbyDomain() + url
}

/**
 * bulk approve transactions
 * @param {String} corporationId
 * @param {Array} transactions array of transaction hashids
 * @return {Promise}
 */
export const bulkApproveTransactions = (corporationId: string, transactions: string[]): Promise<SuccessResponsePayload> => {
  return axios.put(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/transaction_ledger/bulk_approval`), { transactions })
}

/**
 * bulk close transactions
 * @param {String} corporationId
 * @param {Array} transactions array of transaction hashids
 * @return {Promise}
 */
export const bulkCloseTransactions = (corporationId: string, transactions: string[]): Promise<SuccessResponsePayload> => {
  return axios.put(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/transaction_ledger/bulk_closure`), { transactions })
}

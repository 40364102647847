import axios from '@lib/axios/middleware'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'

const getImbyRoute = (url) => {
  if (window.location.hostname === 'localhost') return url
  return getImbyDomain() + url
}

/**
 * Update suitability status and note for transaction
 * @param {String} transactionId
 * @param {Object} payload
 * @return {Promise}
 */
export const updateSuitabilityAudit = (transactionId, payload) => {
  return axios.patch(getImbyRoute(`/api/addy_plus/v1/transactions/${transactionId}/suitability_audit`), payload)
}

/**
 * Upload transaction all contracts
 * @param {String} transactionId
 * @param {Object} formData
 * @return {Promise}
 */
export const uploadTransactionAllContracts = (transactionId, formData) => {
  return axios.patch(getImbyRoute(`/api/addy_plus/v1/transactions/${transactionId}/upload_all_contracts`), formData)
}

/**
 * Update custom property maximum limits for investor
 * @param {String} investorId
 * @param {Object} payload
 * @return {Promise}
 */
export const updateMaximumLimits = (investorId, payload) => {
  return axios.patch(getImbyRoute(`/api/addy_plus/v1/investors/${investorId}/custom_maximum_limits`), payload)
}

/**
 * request CSR suitability status upload
 * @param {String} corporationId
 * @param {Object} formData
 * @return {Promise}
 */
export const csrSuitabilityStatusUpload = (corporationId, formData) => {
  return axios.put(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/csr_suitability_status_upload`), formData)
}

/**
* Fetch total amount invested in the transaction's property
* @param {String} investorId
* @param {String} transactionId
* @return {Promise}
*/
export const fetchInvestorSumInProperty = (investorId, transactionId) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/investors/${investorId}/property_investment_sum?transaction_id=${transactionId}`))
}

/**
 * Fetch KYC manual verification paper trail
 * @param {String} investorId
 * @return {Promise}
 */
export const fetchInvestorKycPaperTrail = (investorId) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/investors/${investorId}/kyc_paper_trail`))
}

/**
 * Manual approve KYC status and update note for investor
 * @param {String} investorId
 * @param {Object} payload
 * @return {Promise}
 */
export const manualApproveKyc = (investorId, payload) => {
  return axios.patch(getImbyRoute(`/api/addy_plus/v1/investors/${investorId}/kyc_status_manual_approval`), payload)
}

<template>
  <div :class="`modal ${!!keyIdentifier ? 'is-active' : ''}`">
    <div class="modal-background"></div>
    <div class="modal-content has-background-white has-text-centered">
      <img src="@assets/icons/close.svg" alt="close" class="icon-close is-clickable" @click="closeModal">
      <div class="modal-title has-text-blue has-text-large is-capitalized mb-4">{{ docType | formatWord }}</div>
      <p v-if="file" class="is-size-6 py-4">{{ file.name }}</p>
      <b-upload v-model="file" expanded>
        <a class="button is-fullwidth">
          <b-icon icon="upload"></b-icon>
          <span>Click to upload</span>
        </a>
      </b-upload>
      <b-button
        type="is-blue"
        :class="`has-text-weight-bold mt-4 ${isUploading ? 'is-loading' : ''}`"
        :disabled="!file || isUploading"
        @click="submit"
        >Submit</b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    transactionId: {
      type: String,
    },
    docType: {
      type: String,
    },
  },
  computed: {
    keyIdentifier() {
      if (!this.transactionId || !this.docType) return null
      return `${this.transactionId}${this.docType}`
    },
  },
  data() {
    return {
      file: null,
      isUploading: false,
    }
  },
  filters: {
    formatWord(value) {
      return value?.replace(/_+/g, ' ')
    },
  },
  watch: {
    keyIdentifier() {
      this.isUploading = false
      this.file = null
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    submit() {
      if (!this.file) return

      this.isUploading = true
      const formData = new FormData()
      formData.append(this.docType, this.file)

      this.$emit('onSubmit', formData)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.modal {
  z-index: 101;
  .modal-content {
    max-width: $mobile-wrapper-width;
    position: relative;
    border-radius: 12px;
    padding: 24px 40px;
    font-size: 16px;
    .icon-close {
      width: 40px;
      position: absolute;
      left: 16px;
      top: 14px;
    }
    .modal-title {
      &.has-text-small {
        font-size: 26px;
      }
      &.has-text-large {
        font-size: 36px;
      }
      font-weight: 600;
      padding-bottom: 10px;
    }
  }
}
</style>
